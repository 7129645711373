<template>
<b-card-body>
  <template v-if="!dataList">
    <div class="text-center text-danger my-2">
      <b-spinner class="align-middle"></b-spinner>
      <strong>Loading...</strong>
    </div>
  </template>
  <template v-else-if="(dataList||[]).length">
    <b-row cols="1" cols-md="2" cols-lg="4" class="gutter-2">
      <b-col
        v-for="(item,key) in dataList" 
        :key="key"
      >
        <div class="post-block">
          <div class="post-block-media">
            <b-img
              fluid-grow
              class="post-block__img"
              :src="parent.uploader(item.ae_thumbnail)"
            />
            
            <LabelStatus :item="item" mode="grid"/>
            
            <div class="post-block-actions">
              <b-button class="btn-icon" size="sm" variant="warning"
                v-if="moduleRole('edit')"
                :to="{name: $route.name, params: {pageSlug: item[idKey]}}"
              >
                <i class="fas fa-pencil-alt"></i>
              </b-button>
              <b-button class="btn-icon" size="sm" variant="danger"
                v-if="moduleRole('delete')"
                @click="doDelete(key, item)"
              >
                <i class="fas fa-trash-alt"></i>
              </b-button>
            </div>
          </div>
          <div class="post-block-body">
            <!-- <span class="post-block__cat">{{ item.abc_name_id }}</span> -->
            <h4 class="font-weight-bold post-block__title">{{ item.ae_title_id }}</h4>
            <span class="post-block__meta">
              <i class="icon-user"></i> {{ item.ae_author }}
            </span>
            <span class="post-block__meta">
              <i class="ti-calendar"></i> {{ item.ae_publish_date | moment('LL') }}
            </span>
          </div>
          <div class="post-block-statistics">
            <span class="post-block-statistics__item">
              <i class="icon-eye"></i> {{ item.ae_count_of_view||0 }}
            </span>
          </div>
        </div>
      </b-col>
    </b-row>
  </template>
  <template v-else>
    <h4 align="center"><span v-if="Object.keys(filterData).length">No search results found</span><span v-else>No {{ pageTitle }} listed yet</span></h4>
  </template>
</b-card-body>
</template>

<script>
import LabelStatus from './LabelStatus'

export default{
  props:{
    data:[Array, Object, Boolean],
    dataList:[Array, Object, Boolean],
    perPage:[Number, String],
    idKey:String,
    filter:Object,
    pageTitle:String
  },
  components:{LabelStatus},
  data(){
    return {
    }
  },
  computed:{
    filterData(){
      let filterData = JSON.parse(JSON.stringify(this.filter))
      delete filterData.viewMode
      delete filterData.page
      return filterData
    },
    parent(){
      return this.$parent.$parent.$parent
    }
  },
  methods:{
    moduleRole(menu){
      return this.parent.moduleRole(menu)
    },
    doChangeStatus(index, value){
      return this.parent.doChangeStatus(index, value)
    },
    doDelete(index, value){
      return this.parent.doDelete(index, value)
    },
  }
}
</script>