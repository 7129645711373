<template>
<span>
  <b-badge v-if="item.ae_status=='P'" variant="success" :class="{'post-block__badge':mode=='grid'}">Published</b-badge>
  <b-badge v-else-if="item.ae_status=='D'" variant="info" :class="{'post-block__badge':mode=='grid'}">Draft</b-badge>
  <b-badge v-else-if="item.ae_status=='N'" variant="danger" :class="{'post-block__badge':mode=='grid'}">Unpublished</b-badge>
</span>
</template>

<script>
export default{
  props:{
    item:Object,
    mode:{
      type:String,
      default:"table"
    }
  }
}
</script>